// core
import { mapGetters } from 'vuex';
import pdf from 'vue-pdf';

// helpers
import Settings from '@/config/Settings';
import { getInArray } from '@/Helpers/Misc';

// components
// import Bookmark from '@/Views/_Components/Bookmark/index.vue';

// icons
import { ChevronLeftIcon, ChevronRightIcon, MenuIcon, SearchIcon, BookmarkIcon, XIcon } from 'vue-feather-icons';

export default {
	name: 'MagazineReader',

	mounted() {
    let {magazine_name, theme_title} = this.magazine;
    let title = magazine_name ? magazine_name : '';
    let subtitle = theme_title ? ' - ' + theme_title : '';

		Settings.title(title + subtitle);
	},

	data() {
		return {
      loading: true,
      loadedRatio: 0,
		};
	},

	components: {
    // 'oston-reader-bookmark': Bookmark,
		'pdf-reader': pdf,

		// icons
		'feather-icon-chevron-left': ChevronLeftIcon,
		'feather-icon-chevron-right': ChevronRightIcon,
		'feather-icon-menu': MenuIcon,
		'feather-icon-search': SearchIcon,
		'feather-icon-bookmark': BookmarkIcon,
		'feather-icon-close': XIcon
	},

	methods: {
		next: function() {
			this.loading = true;
			_.controller('reader').set({
        magazine: this.magazine,
				page: this.page + 1,
				content: this.pages[this.page + 1]
			});
		},
		prev: function() {
			this.loading = true;
			_.controller('reader').set({
        magazine: this.magazine,
        page: this.page - 1,
				content: this.pages[this.page - 1]
			});
		}
	},

	computed: mapGetters({
		page: 'ReaderModel/page',
		totalPages: 'ReaderModel/totalPages',
		content: 'ReaderModel/content',
		magazine: 'MagazineModel/magazine',
		pages: 'MagazineModel/pages'
	})
};
